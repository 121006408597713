@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

body {
	margin: 0;
	padding: 0;
	font-family: 'Poppins', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 62.5%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
	box-sizing: border-box;
	overflow: hidden;
}
